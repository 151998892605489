
<template>
  <div class="member-tree-chart-container">
    <CCard>
      <CCardBody v-if="!!treeData">
        <h3>{{treeData.affiliate_type_name}}</h3>

        <div class="member-details-info mb-3">
          <div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="horizontal"
                :id="inputId"
              />
              <label class="form-check-label" :for="inputId">{{$t('MEMBER_TREE_CHART_COMPONENT_LABEL_HORIZONTAL')}}</label>
            </div>
          </div>
        </div>

        <CRow>
          <CCol lg="12" md="12">
            <vue2-org-tree
              :data="treeData"
              :horizontal="horizontal"
              :collapsable="collapsable"
              :label-class-name="labelClassName"
              :render-content="renderContent"
              :label-width="labelWidth"
              @on-expand="onExpand"
            />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapState, mapActions } from 'vuex';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'MemberTreeChart',
  components: {
  },
  data() {
    return {
      inputId: uuidv4(),
      horizontal: false,
      collapsable: false,
      expandAll: true,
      labelWidth: 250,
    };
  },
  props: {
    treeData: {
      type: Object,
      default: null,
    },
  },
  async mounted() {

  },
  methods: {
    renderContent: function(h, data) {
      return data.ext_client_id;
    },
    labelClassName(data) {
      return 'bg-white';
    },
    onExpand: function(data) {
      if ('expand' in data) {
        data.expand = !data.expand;

        if (!data.expand && data.children) {
          this.collapse(data.children);
        }
      } else {
        this.$set(data, 'expand', true);
      }
    },
    collapse: function(list) {
      var _this = this;
      list.forEach(function(child) {
        if (child.expand) {
          child.expand = false;
        }

        child.children && _this.collapse(child.children);
      });
    },
    expandChange: function() {
      this.toggleExpand(this.data, this.expandAll);
    },
    toggleExpand: function(data, val) {
      var _this = this;
      if (Array.isArray(data)) {
        data.forEach(function(item) {
          _this.$set(item, 'expand', val);
          if (item.children) {
            _this.toggleExpand(item.children, val);
          }
        });
      } else {
        this.$set(data, 'expand', val);
        if (data.children) {
          _this.toggleExpand(data.children, val);
        }
      }
    },
    setActive(node, isActive) {
      if (!isActive) {
        node.actived_flg = false;
      }

      if (node.children && node.children.length > 0) {
        node.children.forEach(childNode => {
          childNode.actived_flg = childNode.actived_flg && isActive;

          this.setActive(childNode, childNode.actived_flg);
        });
      }

      return node;
    },
  },
};
</script>

<style lang="scss">
.member-tree-chart-container {
  padding: 0 15px;

  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }
  .card-body {
    padding-top: 10px;
    padding-bottom: 10px;

    .org-tree-container {
      overflow-y: auto;
      display: block;

      .org-tree-node-label {
        font-size: 0.8em;
        font-weight: 500;
        cursor: pointer;

        > .org-tree-node-label-inner {
          overflow: hidden;
          // padding: 0;
        }
      }
    }
  }

  .org-tree-node-label {
    white-space: nowrap;
  }
  .bg-white {
    background-color: white;
  }
  .bg-orange {
    background-color: orange;
  }
  .bg-gold {
    background-color: gold;
  }
  .bg-gray {
    background-color: lightgray;
    font-weight: normal;
  }
  .bg-lightpink {
    background-color: lightpink;
  }
  .bg-chocolate {
    background-color: chocolate;
  }
  .bg-tomato {
    background-color: tomato;
  }
}
</style>
