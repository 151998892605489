<template>
  <div id="member-tree-chart-details-container">
    <CCard>
      <CCardBody>
        <div class="member-details-info mb-3">
          <h3 class="font-weight-bold mb-4">{{$t('MEMBER_TREE_CHART_PAGE_TITLE')}}</h3>
        </div>

        <CRow>
          <CCol lg="12" md="12">
            <div v-if="isLoading">
              <vcl-list></vcl-list>
            </div>

            <MemberTreeChart
              v-else
              v-for="(data,index) in treeCharts"
              :key="index"
              :treeData="data"
            />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { VclList } from 'vue-content-loading';
import MemberTreeChart from '@/components/Member/MemberTreeChart';
import { mapState, mapActions } from 'vuex';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'TreeChart',
  components: {
    VclList,
    MemberTreeChart,
  },
  data() {
    return {
      isLoading: true,
      memberId: null,
      treeCharts: [],
    };
  },
  async mounted() {
    this.memberId = this.$route.params.memberId;
    this.getTreeChart();
  },
  methods: {
    async getTreeChart() {
      try {
        const result = await this.$http.get(endpoints.getMemberTreeChart(this.memberId), {});

        this.treeCharts = result.data;
        this.isLoading = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
          duration: 2000,
        });

        return null;
      }
    },
  },
};
</script>

<style lang="scss">
#member-tree-chart-details-container {
  padding: 0 24px;
  $green: #4dc1a1;

  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }
  .card-body {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
</style>
